.pageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
}

.pageContent {
    width: 100%;
    max-width: 1280px;
}

.iframeContainer {
    width: 100%;
    height: 80vh;
    min-height: 80vh;
}

.iframeContainer > iframe{
    width: 100%;
    height: 100%;
}