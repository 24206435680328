.pageContainer {
    width: 100%;
    margin-top: 1rem;
    height: 82vh;
}

.contentContainer {
    min-width: 300px;
    overflow-y: auto;
    height: 100%;
}

.menuContainer {
    width: 16rem;
    overflow-y: auto;
    height: 100%;
}

.goToTop {
    display: none;
}

.menuBttn {
    position: fixed;
    left: 1rem;
    top: 5rem;
}

@media only screen and (max-width: 600px) {
    .menuContainer {
        width: 100%;
    }
    .goToTop {
        display: block;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 9999;
    }

    .pageContainer {
        height: 100%;
    }
}