.pageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
}

.pageContent > p {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.pageContent {
    width: 100%;
    max-width: 1280px;
}

.pageContent > p > img {
    max-width: 640px;
    width: 100%;
    object-fit: contain;
}