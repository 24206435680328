.pageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.pageContent {
    width: 100%;
    max-width: 1280px;
    min-height: 80vh;
    padding-left: 1rem;
    padding-right: 1rem;
}

.iframeContainer {
    width: 100%;
    height: 80vh;
    min-height: 80vh;
}

.iframeContainer > iframe{
    width: 100%;
    height: 100%;
}

.thumbImgContainer {
    width: 10%;
}

.thumbImgContainer > img {
    height: 4rem;
    width: 100%;
    object-fit: cover;
}
