St
.pageContainer {
    background-color: #222;
    display: inline-block;
    width: 100%;
    max-width: 100vw;
}

.contentContainer{
    position: absolute;
    top: 0;
    left: 0;
}

.headerCarouselTextContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
}

.carouselItem {
    position: relative;
}

.carouselItem > img {
    height: 100vh;
    object-fit: cover;
}

.headerCarouselTextMain {
    font-size: 4em;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 8px;
    text-align: left;
    background-color: rgba(100,100,100,0.05);
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.headerCarouselTextSub {
    border-top: #fff 1px solid;
    font-size: 1.5em;
    font-weight: 400;
    color: #ffffff;
    text-align: left;
    margin-left: 12px;
}

.textMinWidth {
    min-width: 300px;
}

.easyScrollPad {
    position: absolute;
    top: 60vh;
    height: 40vh;
    left: 0;
    width: 100%;
    background-color: rgba(0,0,0,0);
    z-index: 1000;
}

.maxWidthMuitiCarousel {
    max-width: 98vw;
    overflow: hidden;
}

.MultiCarouselItemImg {
    max-width: 220px;
    max-height: 220px;
    object-fit: contain !important;
}

.contactContainer{
    background-color: #252525;
}

.contactInfoContainer{
    background-color: #00bcd4;
}

.submitMsgBttn:disabled{
    color: #5a5a5a;
    cursor: not-allowed;
}

.submitMsgBttn:hover{
    background-color: rgba(255,255,255,0.2);
}

@media only screen and (max-width: 600px) {
    .contactInfoSection {
        margin-top: 5rem;
    }
    .formSection {
        max-width: 80vw;
    }
}