.container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.2);
    z-index: 20000;
}

.content {
    max-width: 95vw;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #ffffff;
    border-radius: 1rem;
    max-height: 90vh;
    overflow: auto;
}

.closeIcon {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: end;
    margin-bottom: 1rem;
}