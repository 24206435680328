.pageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
}

.pageContent {
    width: 100%;
    max-width: 1280px;
}

.pdfContainer {
    width: 100%;
}

.pdfFrame {
    width: 100%;
    height: 90vh;
}