.navBarContainer {
    position: fixed;
    top: 0;
    min-height: 4em;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
    transition: 1s ease-in-out;
    z-index: 10000;
    max-width: 100vw;
}

.navBarContainerBGClear {
    background-color: rgba(100,100,100,0.1);
}

.navBarContainerBGOpaque {
    background-color: rgba(34,34,34, 1);
}

.navBarContent {
    height: 100%;
    width: 100%;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}

.logo {
    width: 5em;
    min-width: 64px;
}

.rightMostContainer {
    width: 75%;
    min-width: 180px;
}

.menuContainer {
    color: #fff;
    display: flex;
    list-style-type: none;
    justify-content: flex-end;
}

.menuItem {
    margin-left: 16px;
    font-size: 1em;
    font-weight: 800;
    transition: all 0.1s ease-in-out;
}

.menuItem:hover {
    font-size: 1.01em;
}

.toggleButton {
    display: none;
    cursor: pointer;
    z-index: 10;
    border: none;
    background-color: rgba(0,0,0,0);
}

.toggleContent {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 5;
}

.toggleMenuContainer {
    color: #222;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    justify-content: center;
    height: 100%;
    margin-left: 10vw;
    overflow: scroll;
}

.toggleItem {
    font-size: 1.75em;
    margin-bottom: 1.5em;
}

.clickable {
    cursor: pointer;
}

.bar1, .bar2, .bar3 {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
    z-index: 11;
}

.backIconToggle {
    font-size: 36px;
    left: 8vw;
    top: 20px;
    display: block;
    position: absolute;
    opacity: 0;
}

.backIcon {
    font-size: 32px;
    margin-right: 16px;
    display: inline-block;
    opacity: 0;
    margin-top: -6px;
}

.appear {
    opacity: 1;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-7px, 6px);
    transform: rotate(-45deg) translate(-7px, 6px);
    background-color: #222;
}
  
.change .bar2 {opacity: 0;}
  
.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -6px);
    transform: rotate(45deg) translate(-6px, -6px);
    background-color: #222;
}

@media only screen and (max-width: 600px) {
    .toggleContent {
        display: block;
    }
    .menuContainer {
        display: none;
    }
    .navBarContainer {
        padding-left: 16px;
        padding-right: 16px;
    }
    .rightMostContainer {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
    }
    .toggleButton {
        display: block;
    }
}
